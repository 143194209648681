var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-8"},[_c('v-app-bar',{attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),_c('v-toolbar-title',{staticClass:"breadcrumbs d-flex align-center"},[_c('v-icon',{staticClass:"text-light-blue"},[_vm._v(_vm._s(_vm.icons.arrowLeft))]),_c('router-link',{staticClass:"text-decoration--none",attrs:{"to":{ name: 'reports' }}},[_vm._v("Reports")])],1)],1),(_vm.reportDetail)?_c('div',{staticClass:"ma-4"},[_c('div',{staticClass:"col-md-6 col-sm-12 col-xs-12 my-6"},[_c('v-form',{ref:"form",staticClass:"report-form"},[_c('v-divider'),_c('h3',{staticClass:"py-5 primary--text"},[_vm._v("Report Details")]),_c('br'),_c('v-card',{staticClass:"detail-wrapper py-5 px-6"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"text-field-label"},[_vm._v("Report Type: ")]),_c('span',{staticClass:"report"},[_vm._v(_vm._s(_vm.reportDetail.report_type))])]),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"text-field-label"},[_vm._v("Reporter: ")]),_c('user-avatar',{staticClass:"mr-2",attrs:{"avatar":_vm.report.reporter.avatar,"size":"30"}}),_c('router-link',{attrs:{"to":{
                name: 'user.details',
                params: { id: _vm.reportDetail.reporter.id },
              }}},[_vm._v(_vm._s(_vm.reportDetail.reporter.full_name))])],1),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"text-field-label"},[_vm._v("Category: ")]),_c('span',[_vm._v(_vm._s(_vm.reportDetail.reason ? _vm.reportDetail.reason.label : ''))])]),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"text-field-label"},[_vm._v("Description: ")]),_c('span',[_vm._v(_vm._s(_vm.reportDetail.description))])]),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"text-field-label"},[_vm._v(_vm._s(_vm.reportableLabel)+": ")]),_c('v-divider'),(_vm.reportDetail.report_type === 'users')?_c('v-card',{staticClass:"detail-wrapper py-5 px-6",attrs:{"solo":""}},[(_vm.reportDetail.reportable.avatar)?_c('div',{staticClass:"mb-4 d-flex align-items-center justify-content-between"},[_c('label',{staticClass:"text-field-label"},[_vm._v("User: ")]),_c('div',{staticClass:"d-flex align-items-center margin-left-10"},[_c('v-img',{staticClass:"avtar_img",attrs:{"lazy-src":_vm.reportDetail.reportable.avatar.url,"src":_vm.reportDetail.reportable.avatar.url,"gradient":"to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)","aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}),_c('router-link',{staticClass:"margin-left-10",attrs:{"to":{
                      name: 'user.details',
                      params: { id: _vm.report.reportable.id },
                    }}},[_vm._v(_vm._s(_vm.reportDetail.reportable.full_name))])],1)]):_vm._e()]):(_vm.reportDetail.report_type === 'bookings')?_c('v-card',{staticClass:"detail-wrapper py-5 px-6",attrs:{"solo":""}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"text-field-label"},[_vm._v("Booked item: ")]),_c('router-link',{attrs:{"to":{
                    name: 'booking.edit',
                    params: { id: _vm.report.reportable.id },
                  }}},[_vm._v(_vm._s(_vm.reportDetail.reportable.listing.title)+" ")])],1),_c('div',{staticClass:"mb-4 inline-flex"},[_c('label',{staticClass:"text-field-label"},[_vm._v("Booking period: "+_vm._s(_vm.formatDate(_vm.reportDetail.reportable.start_date))+" - "+_vm._s(_vm.formatDate(_vm.reportDetail.reportable.end_date)))])])]):(_vm.reportDetail.report_type === 'listings')?_c('v-card',{staticClass:"detail-wrapper py-5 px-6",attrs:{"solo":""}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"text-field-label"},[_vm._v("Listing:")]),_c('router-link',{attrs:{"to":{
                    name: 'listing.edit',
                    params: { id: _vm.report.reportable.id },
                  }}},[_vm._v(_vm._s(_vm.reportDetail.reportable.title))])],1),_c('div',{staticClass:"mb-4"},[_c('v-row',_vm._l((_vm.reportDetail.reportable.photos),function(photo){return _c('v-col',{key:photo.id,staticClass:"d-flex child-flex",attrs:{"cols":"4","sm":"4"}},[_c('v-img',{attrs:{"lazy-src":photo.thumb_url,"src":photo.thumb_url,"gradient":"to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)","aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)}),1)],1)]):_vm._e()],1),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"text-field-label"},[_vm._v("Attachments: ")]),_c('v-divider'),_c('v-card',{staticClass:"detail-wrapper py-5 px-6",attrs:{"solo":""}},[_vm._l((_vm.reportDetail.attachments),function(attachment){return _c('div',{key:attachment.id,staticClass:"mb-4"},[_c('v-img',{attrs:{"lazy-src":attachment.responsive_url,"max-height":"150","max-width":"250","src":attachment.responsive_url}})],1)}),(_vm.reportDetail.attachments.length <= 0)?_c('div',[_vm._v(" No available attachments ")]):_vm._e()],2)],1)])],1)],1)]):_vm._e(),_c('v-snackbar',{attrs:{"right":"","color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},[_vm._v("OK")])],1),_c('v-overlay',{attrs:{"absolute":"","value":_vm.isLoading,"opacity":"0.2"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }